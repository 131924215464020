// :root {
//   --primary-color: #ffff;
//   --secondary-color: #0000;
//   --third-color: #e5e5e5;
// }


$p-color-btn: var(--p-color-btn);
$p-color: var(--p-color);
$s-color: var(--s-color);
$s-color-btn: var(--s-color-btn);
$t-color: var(--t-color);
$h-color: var(--h-color);
$h-bg-color: var(--h-bg-color);
$lp-color: var(--lp-color);

$material-p-color: #3f51b5;
$material-s-color: #dc3545;

$primary-orange-100: #ffa06a;