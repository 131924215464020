/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css');
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './_variables';
@import '../src/assets/fonts/stylesheet.css';
html,
body,
.container-min-height {
  min-height: 100vh;
  height: auto;
  margin: 0;
}

body {
  font-family: 'ABC Favorit', 'Inter', sans-serif !important;
  letter-spacing: 0.001000075em;
  // word-spacing: -5px;
  // word-spacing: -0.319em;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-rendering: optimizeLegibility;
}

//Backgrounds
.bg-grey {
  background-color: grey;
}

.bg-light-outmatic {
  background-color: $s-color !important;
}

.bg-default {
  background-color: var(--theme-bgc, #232732);
}

//Buttons
.btn-primary {
  color: $s-color-btn;
  border-color: $p-color-btn;
  background-color: $p-color-btn;
  border-radius: 10px;
}

.btn-primary:hover,
.btn-primary:focus {
  color: $s-color-btn;
  border-color: $p-color-btn;
  background-color: $p-color-btn;
  opacity: 0.7 !important;
  box-shadow: none !important;
}

input,
textarea {
  font-family: 'ABC Favorit', 'Inter', sans-serif !important;
  padding: 10px 16px;
  border-radius: 10px;
  border: 1px solid var(--Primary-Gray-60, #cdcdcd);
  background: transparent;
  word-spacing: inherit;
}

.btn-primary:disabled {
  color: white;
  border-color: grey;
  background-color: grey;
}

.button-disabled {
  color: white;
  border-color: lightgray;
  background-color: lightgray;
}

.btn-primary-outmatic {
  background-color: black !important;
  border-color: black !important;
  color: $s-color-btn;
}

.btn-primary-outmatic:hover {
  color: yellow !important;
}

.btn-primary-outmatic {
  background-color: black !important;
  border-color: black !important;
  color: $s-color-btn;
}

.btn-primary-outmatic:hover {
  color: yellow !important;
}

//Margins
.m-l-2 {
  margin-left: 2%;
}

.m-r-2 {
  margin-right: 2%;
}

//Heights
.min-h-300 {
  min-height: 300px;
}

.vh10 {
  height: 10vh;
}

.vh-100 {
  min-height: 100vh;
}

.vh-79 {
  min-height: 79vh;
}

.h-200-px {
  height: 200px;
}

.h-80-px {
  height: 80px;
}

/* PC device styles */
@media screen {
  .container-responsive {
    width: 50%;
  }
}

/* Mobile device styles */
@media screen and (max-width: 600px) {
  .container-responsive {
    width: 100%;
  }
}

//Mobile device
@media (max-width: 600px) {
  .ant-menu-submenu.ant-menu-submenu-placement-bottom {
    right: 150px;
  }
  // .card {
  //   border-radius: 0px;
  //   // height: 100vh;
  // }
}

//Spinner
.spinner-border {
  height: 50px;
  width: 50px;
}

.ant-spin-dot > .ant-spin-dot-item {
  background-color: $primary-orange-100;
}

.ant-spin {
  color: $primary-orange-100;
}

//Images
// img {
//   max-height: 38px;
//   max-width: 164px;
// }
//tool-tip
.ant-tooltip-inner {
  padding: 8px;
  border-radius: 8px;
  background: var(--Secondary-Blue, #374a58);
  font-size: 12px;
  font-weight: 400;
  white-space: pre-line;
  // max-width: 164px;
}

//Font-size
.xxx-large {
  font-size: xxx-large;
}

.font-size-x-large {
  font-size: x-large;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-20 {
  font-size: 20px;
}

.fs-28 {
  font-size: 28px;
}

.fs-100 {
  font-size: 100px;
}

//NAVBAR
.navbar {
  background-color: $h-bg-color;
  color: $h-color;
}

//Colors
.primary-color {
  color: $p-color;
}

.secondary-color {
  color: $s-color;
}

//Loader
.container-loader,
.row-loader {
  text-align: center;
  justify-content: center;
}
.card-loader {
  border-radius: 0;
  padding: 50px 25px 50px 25px;
  margin-top: 80px;
}

//Row
.center-row {
  padding: 50px 0px 50px 0px;
}

// .row-group {
//   align-items: baseline !important;
// }

//Cursor
.clickable {
  cursor: pointer;
}

//Box-shadow
.box-shadow-15 {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

.box-shadow-5 {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
}

//Card
.card {
  border-radius: 10px;
}

.payment-method-card {
  height: 100px;
}

//Col
.navabar-dx-col {
  font-size: smaller;
  text-align: right;
}

//Borders

.primary-border {
  border-color: $p-color;
}

.border-10 {
  border-radius: 10px !important;
}

.subscriber-input-border {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

.subscriber-input {
  border-radius: 6px !important;
}

.ant-input-group-addon {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.ant-input-group-addon:last-child {
  padding: 0;
}

// .cdk-focused > .as-label,
// .cdk-focused > span > .as-label {
//   color: $material-p-color !important;
// }

.error-input {
  border-color: $material-s-color !important;
}

.as-label,
.as-placeholder,
.as-label,
.as-placeholder {
  color: grey !important;
}

#securityCodeInputGroup > span > span {
  padding: 0;
}

.form-label {
  font-size: 14px;
  line-height: 20px; /* 142.857% */
}

//Form

.form-control,
.form-control:focus,
.form-control:hover {
  border-radius: 10px !important;
  border: 1px solid var(--Primary-Gray-60, #cdcdcd);
  background: var(--Neutrals-White, #fff);
  box-shadow: none;
}

.form-control {
  padding: 10px 16px;
  color: var(--Primary-Blue-100, #161c26);
  &::placeholder {
    color: var(--Primary-Gray-60, #73777d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.form-control:focus,
.form-control:hover {
  border: 1px solid var(--Primary-Orange-100, #ffa06a);
  box-shadow: 0px 0px 0px 4px rgba(255, 198, 162, 0.2);
}

//
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  box-shadow: none;

  .ant-radio-button-wrapper:focus-visible {
    border-color: $p-color;
  }
}
.ant-radio-button-wrapper-checked {
  border-color: $p-color !important;
}
.ant-radio-button-wrapper > .ant-radio-button {
  outline: none;
}

//LanguageMenu
.ant-menu-horizontal {
  border-bottom: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after {
  border-bottom: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-expand-icon,
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: $p-color;
}

.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: $p-color;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active,
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #ffa06a4d;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active,
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #ffa06a4d;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active,
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #ffa06a4d;
}

.ant-menu-item-group-title {
  padding: 0;
}

.language-menu {
  text-align: right;
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0;
}

.anticon .anticon-global {
  vertical-align: text-top;
}

.ant-menu-submenu-title .anticon {
  color: #fff !important;
  cursor: pointer;
  svg {
    height: 24px;
    width: 24px;
  }
}

.ant-menu-horizontal {
  line-height: normal;
}
//Checkbox
.check-container {
  width: fit-content;
}
input[type='checkbox'] {
  display: none;
  & + .checkBox {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    background-color: $s-color;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  &:checked + .checkBox {
    background-color: transparent;
    padding: 2px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 9px;
      height: 9px;
      border-radius: 2px;
      background-color: $p-color;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $s-color;
  border-color: $t-color;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: $t-color;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid $t-color;
  border-top: 0;
  border-left: 0;
}

input,
textarea,
select {
  font-size: initial !important;
  font-family: 'ABC Favorit', 'Inter', sans-serif !important;
  -webkit-appearance: none;

  &:placeholder {
    font-family: 'ABC Favorit', 'Inter', sans-serif !important;
  }
}

//LINK
.primary-link {
  color: $p-color;
  text-decoration: none;
}

.primary-link:hover,
.primary-link:focus {
  color: inherit;
  text-decoration: none;
}

.lang-root-menu {
  width: 166px;
  .ant-dropdown-menu {
    border-radius: 16px;
    padding: 8px;
    li {
      padding: 8px 16px;
      font-weight: 400;
    }
  }
}

.ant-btn-primary {
  background-color: $p-color;
  border-color: $p-color;
  color: $s-color;
  border-radius: 10px;
  &:hover {
    background-color: $p-color;
    border-color: $p-color;
    color: $s-color;
    opacity: 0.7 !important;
    box-shadow: none !important;
  }
}

.ant-btn-link {
  color: $p-color;
  border: none;
  background-color: transparent;
  &:hover {
    color: $p-color;
  }
  &:focus {
    color: $p-color;
  }
}
